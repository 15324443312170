<!--
 * @Descripttion: 登录页面组件
 * @Author: Banbri
 * @Date: 2021-06-11 11:08:34
 * @LastEditors: Banbri
 * @LastEditTime: 2021-05-20 16:49:25
--> 

<template>
  <div class="payment">
    <div class="payment-content" v-if="order">
      <el-card class="box-card" shadow="hover">
        <div slot="header" class="payment-clearfix">
          <span>支付订单</span>
        </div>
        <div class="payment-item">
          <div class="payment-paytype-header">
            <span>支付方式</span>
          </div>
          <div class="payment-paytype">
            <div class="select">
              <a href="javascript:;">
                <img src="http://xmall.exrick.cn/static/images/alipay@2x.png" />
              </a>
            </div>
          </div>
          <div class="pay-operate">
            <div class="pay-price">
              <span>订单金额:</span>
            </div>
            <div class="pay-price-num">
              <span>{{ order.discount_price * order.num }}元</span>
            </div>
            <div class="should-pay">
              <span>实际应付金额:</span>
            </div>
            <div class="pay-price-num">
              <span>{{ order.discount_price * order.num }}元</span>
            </div>
            <div class="pay-btn">
              <el-button type="primary" @click="pay()">立即支付</el-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="not-found" v-else>查询不到该订单</div>
  </div>
</template>
<script>
export default {
  name: 'Payment',
  data() {
    return {
      orderNum: '',
      order: '',
      form: {
        order_num: '',
        pay_type: 'alipay'
      },
      timestamp:''
    }
  },
  activated() {
    const { orderNum, out_trade_no,timestamp } = this.$route.query
    this.orderNum = orderNum || out_trade_no
    if(out_trade_no){
      this.confirm(); // 弹出确认框
      this.timestamp = timestamp;
      this.updateOrder()
    }
  },
  watch: {
    // 监听商品id的变化，请求后端获取商品数据
    orderNum: function () {
      this.load()
    }
  },
  methods: {
    load() {
      this.$axios
        .post('/api/order/getOrderByOrderNum', {
          order_num: this.orderNum
        })
        .then((res) => {
          if (res.data.code === '001') {
            this.order = res.data.order
          } else {
            this.notifyError(res.data.msg)
          }
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    pay() {
      const { order } = this
      this.$axios
        .post('/api/pay/payment', {
          order
        })
        .then((res) => {
          location.href = res.data
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    confirm() {
      this.$confirm('您已购买成功，您可以', '支付成功提示', {
        confirmButtonText: '回到主页',
        cancelButtonText: '查看订单',
        type: 'success',
        showClose: false,
        closeOnClickModal: false
      })
        .then(() => {
          this.$router.replace({ path: '/' })
        })
        .catch(() => {
          this.$router.replace({
            path: '/order/details',
            query: { orderNum: this.orderNum }
          })
        })
    },
    updateOrder(){
      const { orderNum,timestamp } = this
      this.$axios
        .post('/api/user/order/updateOrder', {
          order_num : orderNum,
          updated_at : timestamp
        })
        .then(() => {
          // console.log(res.data);
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }
    /*
    queryOrder(){
      this.$axios
      .post("/api/pay/queryOrder", {
        orderId: this.orderNum
      })
      .then(res => {
        this.queryOrderAlipay(res.data)
      })
      .catch(err => {
        return Promise.reject(err);
      });
    },
    queryOrderAlipay(url){
      this.$axios
      .get(url)
      .then(res => {
        console.log('res: ', res.data.alipay_trade_query_response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
    },
    timer(){
      setInterval(() => {
        // 每隔 1.5 秒向支付宝发送一次请求，如果支付成功，则向服务器发送请求，更新 type 为 2 ,更新时间为
      }, 1500);
    }
    */
  }
}
</script>

<style scoped>
.payment .payment-content {
  width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.payment .payment-content .payment-item .payment-tips {
  width: 600px;
  margin: 0 auto;
  text-align: center;
  color: #757575;
}
.payment .payment-content .payment-item .payment-form {
  width: 550px;
  margin: 0 auto;
  margin-top: 50px;
}
.payment .payment-content .payment-item .payment-paytype-header {
  width: 700px;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.payment .payment-content .payment-item .payment-paytype {
  display: flex;
  align-items: center;
  height: 130px;
  width: 700px;
  margin: 0 auto;
  border-bottom: 1px solid #e0e0e0;
}
.payment .payment-content .payment-item .payment-paytype img {
  height: 60px;
  width: 240px;
}
.payment .payment-content .payment-item .payment-paytype .no-select {
  border-radius: 10px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  margin-right: 50px;
}
.payment .payment-content .payment-item .payment-paytype .select {
  margin-right: 50px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #0400ff;
}
.payment .payment-content .pay-operate {
  width: 700px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 60px;
}
.payment .payment-content .pay-operate .pay-price {
  margin-left: 170px;
  height: 20px;
}
.payment .payment-content .pay-operate .pay-price-num {
  font-size: 30px;
  color: #ff6700;
  width: 110px;
  margin-left: 10px;
}
.payment .payment-content .pay-operate .should-pay {
  margin-left: 20px;
  height: 20px;
}
.payment .payment-content .pay-operate .pay-btn {
  height: 30px;
}

/*v-else*/
.payment .not-found {
  height: 500px;
  text-align: center;
}
/*v-else END*/
</style>
